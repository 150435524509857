export function Image({ imgSrc, className, fadeDirection }) {
    return (
        <div>
            <div
                className="image"
                data-aos={`fade-${fadeDirection}`}
                data-aos-animate="true"
                data-aos-once="false"
                data-aos-delay="50"
                data-aos-duration="1200"
            >
                <img
                    src={imgSrc}
                    className={className}
                    alt="Services Details"
                    width={750}
                ></img>
            </div>
        </div>
    );
}
