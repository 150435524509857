import React from "react";
import {ColoredText} from "./ColoredText";

export function FunFactSquare(props) {
    const {children, bxIcon, numberToDisplay, animationDelay} = props;

    return (
        <>
            <div className={"flex flex-col items-center border border-gray-100 w-60 h-60 p-5"}
                 data-aos="fade-up"
                 data-aos-once="false"
                 data-aos-delay={animationDelay}
                 data-aos-duration="1200">
                <div className={"bg-gray-100 h-24 w-24 flex justify-center items-center rounded-full"}>
                    <i className={`bx ${bxIcon} text-4xl text-green-400`}></i>
                </div>
                <ColoredText color={"orange-500"}>{numberToDisplay}</ColoredText>
                {children}
            </div>
        </>
    )
}
