import {Card} from "./Card";
import {BannerArea} from "./BannerArea";

export function Services(props) {
    const {services} = props;

    return (
        <BannerArea>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 items-stretch">
                {services.map((service, index) => (
                    <Card
                        key={index}
                        title={service.title}
                        description={service.description}
                        icon={service.icon}
                    />
                ))}
            </div>
        </BannerArea>
    );
}
