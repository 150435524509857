export function Badge({href, icon, label}) {
    return (
        <div className="col-sm-6 feature-box mb-3">
            <a
                href={href}
                className="flex items-center ">
                <i className={`${icon} text-teal-500 text-lg mr-2`}></i>
                {label}
            </a>
        </div>
    );
}
