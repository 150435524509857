export function Description(props) {
    const {text, children, className} = props;
    return (
        <p
            className={`${className} text-gray-500 text-lg mb-6`}
            data-aos="fade-up"
            data-aos-once="false"
            data-aos-delay="200"
            data-aos-duration="1200"
        >
            {text}
        </p>
    );
}
