import {Navbar} from "../components/Navbar";
import React from "react";
import {Services} from "../components/Services";
import {DevSection} from "../components/DevSection";
import {CloudSection} from "../components/CloudSection";
import {FunFactSquare} from "../components/FunFactSquare";
import {Footer} from "../components/Footer";
import {Description} from "../components/Description";
import {Intro} from "../components/Intro";
import {AnimatedButton} from "../components/AnimatedButton";
import {WhyChooseUs} from "../components/WhyChooseUs";

export function Homepage(props) {
    const {services, devFeatures, hostingFeatures} = props;

    return (
        <>
            <Navbar/>
            <div className="container-fluid md:mx-52 mb-36">
                <div className="flex flex-col lg:flex-row center items-center py-10 px-6 lg:px-20">
                    <div className="lg:w-1/2 text-center lg:text-left">
                        <Intro
                            title="Vertry"
                            subtitle="Jouw partner in innovatieve IT-oplossingen"
                            description="Betrouwbare IT-oplossingen die jouw bedrijf naar een hoger niveau tillen"
                        />
                        <Description
                            text="Bij Vertry leveren we op maat gemaakte IT-diensten, zoals web- en applicatieontwikkeling. Daarnaast bieden we professionele hosting en onderhoud voor jouw projecten. Wij zorgen ervoor dat jouw technologie probleemloos functioneert, zodat jij je volledig kunt focussen op het laten groeien van je onderneming."
                        />
                        <AnimatedButton className={"logo-purple"}
                                        href="/contact"
                                        spanMargin={"ml-2"}
                                        icon={"bxs-hot"}
                                        animation={'fade-up'}
                        >
                            Ontdek onze oplossingen
                        </AnimatedButton>
                    </div>
                    <div
                        className="lg:w-1/2 mt-10 lg:mt-0 flex justify-center relative"
                        data-aos="fade-up"
                        data-aos-delay="400"
                        data-aos-duration="1200"
                    >
                        <img
                            src="/images/banner-img1.jpg"
                            alt="BannerArea"
                            className="w-3/4 lg:w-2/3 rounded-full shadow-lg"
                        />
                    </div>
                </div>
            </div>
            <Services services={services}/>
            <DevSection devFeatures={devFeatures}/>
            <CloudSection hostingFeatures={hostingFeatures}/>
            <div className={"p-20"}>
                <WhyChooseUs/>
            </div>
            <div className={"flex flex-col justify-end"}>
                <Footer/>
            </div>
        </>
    );
}
