import {Description} from "./Description";
import {FunFactSquare} from "./FunFactSquare";
import React from "react";
import {Contact} from "./Contact";
import {Title} from "./Title";

export function WhyChooseUs() {
    return (
        <>
            <div>
                <div className={"flex justify-center"}>
                    <Title>Waarom kiezen voor ons?</Title>
                </div>
                <div className={"container flex mx-auto"}>
                    <Description className={"p-10"}
                                 text={"Bij ons draait alles om het versterken van jouw online aanwezigheid. Of je nu een startende ondernemer bent of een gevestigde professional, wij bieden op maat gemaakte oplossingen die perfect aansluiten bij jouw behoeften. Met een persoonlijke aanpak, betaalbare tarieven en een passie voor kwaliteit, zorgen wij ervoor dat jouw website of applicatie niet alleen goed functioneert, maar ook indruk maakt. Kies voor ons en ervaar de combinatie van creativiteit, technische expertise en betrouwbare service. Samen bouwen we jouw digitale succes."}></Description>
                </div>
                <div className={"relative flex flex-row flex-wrap justify-center p-10 gap-32"}>
                    <FunFactSquare animationDelay={"100"} bxIcon={"bx-check-square"} numberToDisplay={0}>Afgewerkte
                        Projecten</FunFactSquare>
                    <FunFactSquare animationDelay={"200"} bxIcon={"bx-star"} numberToDisplay={0}>Tevreden
                        Klanten</FunFactSquare>
                    <FunFactSquare animationDelay={"300"} bxIcon={"bx-run"} numberToDisplay={0}>Lopende
                        Projecten</FunFactSquare>
                    <FunFactSquare animationDelay={"400"} bxIcon={"bx-award"} numberToDisplay={0}>Positieve
                        Reviews</FunFactSquare>
                    <img alt className={"absolute bottom-0 lg:top-0"} src={"/images/map1.png"}/>
                </div>
                <Contact/>
            </div>
        </>
    )
}
