import {AnimatedButton} from "./AnimatedButton";

export function Contact(props) {
    const {children} = props;

    return (
        <div className={"flex justify-center items-center p-10 h-full"}>
            <div className={"border border-gray-200 border-1 flex-shrink-0"}>
                <div className={"flex flex-col xl:flex-row justify-between items-center h-full p-10 xl:gap-24"}>
                    <div>
                        <p className={"font-bold text-xl mt-5 whitespace-nowrap"}>Heb je nog vragen of informatie nodig?</p>
                        <p className={"text-gray-500 mt-1 whitespace-nowrap"}>Aarzel dan niet om ons te contacteren.</p>
                    </div>
                    <AnimatedButton
                        spanMargin={"ml-2"}
                        className={"mt-5 flex-shrink-0 logo-purple"}
                        animation={"fade"}
                        icon={"bxs-pencil"}
                        style={{ width: '150px', height: '50px' }}
                    >
                        Contacteer ons
                    </AnimatedButton>
                </div>
            </div>
        </div>
    );
}
