import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export function Footer() {
    const {t} = useTranslation();

    return (
        <>
            <footer className="relative bg-gray-800 text-white py-10 bottom-0"
            >
                <div className="absolute inset-0">
                    <img
                        src="/images/footer-shape2.png"
                        alt="background"
                        className="w-full h-full object-cover opacity-50"
                    />
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>
                <div className="relative container mx-auto px-4 "
                     data-aos="fade-right"
                     data-aos-once="false"
                     data-aos-delay="100"
                     data-aos-duration="1800">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        <div className="space-y-4">
                            <div className="logo">
                                <a href="/"><img src="/images/vertry-h-white.svg" alt="image" width={150}/></a>
                            </div>
                            <p>Betrouwbare IT-oplossingen.</p>
                        </div>
                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold">{t('Bedrijf')}</h3>
                            <ul className="space-y-2">
                                <li>
                                    <Link to="/about" className="hover:underline">{t('Over ons')}</Link>
                                </li>
                                <li>
                                    <Link to={"/services"} className="hover:underline">{t('Diensten')}</Link>
                                </li>
                                <li>
                                    <Link to={"/pricing"} className="hover:underline">{t('Prijzen')}</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold">Support</h3>
                            <ul className="space-y-2">
                                <li>
                                    <Link to={"/faq"} className="hover:underline">{t('FAQ\'s')}</Link>
                                </li>
                                <li>
                                    <Link to={"/contact"} className="hover:underline">{t('Contacteer ons')}</Link>
                                </li>
                                <li><Link to={"/terms-conditions"}
                                          className="hover:underline">{t('Terms & Conditions')}</Link>
                                </li>
                                <li>
                                    <Link to={"/contact"} className="hover:underline">{t('Contacteer ons')}</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold">{t('Contact Info')}</h3>
                            <ul className="space-y-2">
                                <li>{t('Locatie')}: <span>Booischot, België</span></li>
                                <li>Email: <span>info@vertry.be</span></li>
                                <li>Phone: <span>+324/71.71.77.85</span></li>
                            </ul>
                            <ul className="flex space-x-4">
                                <li><Link to={"/https://www.facebook.com/"} target="_blank"
                                          className="hover:text-gray-400"><i
                                    className="bx bxl-facebook"></i></Link></li>
                                <li><Link to={"/https://www.linkedin.com/"} target="_blank" className="hover:text-gray-400"><i
                                    className="bx bxl-linkedin"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="text-center mt-8">
                        <p>Copyright © 2024 Dean Vervaeck.</p>
                    </div>
                </div>
            </footer>
        </>
    );
}
