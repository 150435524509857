import {Navbar} from "../components/Navbar";
import {Footer} from "../components/Footer";
import {useTranslation} from "react-i18next";
import {Description} from "../components/Description";
import React from "react";
import {ColoredBanner} from "../components/ColoredBanner";
import {WhyChooseUs} from "../components/WhyChooseUs";
import {Experts} from "../components/Experts";
import {Title} from "../components/Title";
import {AnimatedButton} from "../components/AnimatedButton";

export function About() {
    const {t} = useTranslation();

    const experts = [
        {
            name: "Dean Vervaeck",
            jobTitle: "Oprichter / Ontwikkelaar",
            imgSrc: "/images/experts/expert1.png",
        },
    ];


    return (
        <>
            <Navbar/>
            <ColoredBanner/>
            <div className="md:mx-52 mb-20 p-10 lg:p-0 lg:mt-20">
                <div className="flex flex-col lg:flex-row center items-center py-10 px-6 lg:px-20">
                    <div className="lg:w-1/2 text-center lg:text-left">
                        <Title className={"text-4xl font-bold"}>Hoe is het idee ontstaan?</Title>
                        <Description
                            className={"mt-2"}
                            text="Ons verhaal begon met een gedeelde passie voor innovatie en klantgerichte oplossingen. Wat startte als een klein idee, groeide al snel uit tot een missie om bedrijven en individuen te ondersteunen in hun digitale transformatie. Met hard werk, toewijding en een focus op kwaliteit, zijn we een platform aan het opbouwen waarmee we oplossingen bieden die écht het verschil kunnen maken."
                        />
                    </div>

                    <div
                        className="lg:w-1/2 mt-10 lg:mt-0 flex justify-center relativef"
                        data-aos="fade-up"
                        data-aos-delay="400"
                        data-aos-duration="1200"
                    >
                        <img
                            src="https://hepro-react.envytheme.com/images/about-img.jpg"
                            alt="BannerArea"
                            className="w-3/4 lg:w-2/3 shadow-lg"
                        />
                    </div>
                </div>
            </div>
            <div className={"bg-[#f4f6fc]"}>
                <div className={"flex justify-center items-center flex-col p-20"}>
                    <Title className={"mb-10"}>Wie zijn wij?</Title>
                    <Experts experts={experts}/>
                </div>
            </div>
            <div className={"p-20"}>
                <WhyChooseUs/>
            </div>
            <div>
                <div className={"bg-[#f4f6fc]"}>
                    <div className={"relative flex justify-center items-center content-center p-20 h-96"}>
                        <div className="shape10 animate-spin"><img
                            src="https://hepro-react.envytheme.com/images/shape/shape10.png"
                            alt="image"/></div>
                        <div className="shape11 animate-bounce-c"><img
                            src="https://hepro-react.envytheme.com/images/shape/shape7.png"
                            alt="image"/></div>
                        <div className={"flex flex-col items-center lg:gap-y-4 xl:gap-y-12"}>
                            <Title className={"text-center lg:w-1/2 xl:w-full"}>Wij proberen je altijd zo goed mogelijk
                                bij te
                                staan.</Title>
                            <AnimatedButton href="/contact"
                                            spanMargin={"ml-2"}
                                            icon={"bxs-hot"}
                                            className={"logo-gradient logo-purple-hover hover:text-white"}
                            >Contacteer ons
                            </AnimatedButton>
                        </div>
                        <div className="shape12 animate-spin"><img
                            src="https://hepro-react.envytheme.com/images/shape/shape11.png" alt="image"/></div>
                        <div className="shape13 animate-spin"><img
                            src="https://hepro-react.envytheme.com/images/shape/shape12.png" alt="image"/></div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}
