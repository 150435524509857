import React from "react";
import {useTranslation} from "react-i18next";

export function ColoredBanner() {
    const {t} = useTranslation()
    return(
        <>
            <div className="relative">
                {/* Background Image */}
                <img
                    src="/images/bg_lines.svg"
                    alt="background"
                    className="absolute inset-0 w-full h-full object-cover opacity-40 z-10"
                />
                {/* Page Title Area */}
                <div className="relative">
                    <div className="page-title-area">
                        <div className="text-center z-10">
                            <div className="page-title-content">
                                <h2 className="text-white text-4xl">{t('Over ons')}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
