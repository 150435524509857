import {Link} from "react-router-dom";

export function AnimatedButton(props) {
    const {children, href, text, icon, animation, className, spanMargin} = props;
    return (
        <button data-aos={`${animation}`}
                data-aos-once="false"
                data-aos-delay="300"
                data-aos-duration="1200"
                className={`button-size logo-gradient-hover text-white font-medium px-6 py-3 rounded-lg shadow-lg transition cursor-pointer ${className} `}>
            <Link path={href}>
                <div className={"flex items-center"}>
                    <i className={`bx ${icon}`}></i>
                    <span className={spanMargin}>{text}{children}</span>
                </div>
            </Link>
        </button>
    );
}
