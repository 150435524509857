import {Image} from "./Image";
import React from "react";
import {AnimatedButton} from "./AnimatedButton";
import {ServiceDescription} from "./ServiceDescription";

export function CloudSection(props) {
    const {hostingFeatures} = props

    return (
        <div className="relative bg-[#f4f6fc]">
            <div className="grid grid-cols-1 lg:grid-cols-2 mt-8 p-10 md:p-20 gap-x-24 mx-auto">
                <div className={"flex items-center justify-center"}>
                    <Image
                        className={"lg:mt-36 xl:mt-0"}
                        imgSrc="/images/service2.png"
                        fadeDirection={"right"}
                    />
                </div>
                <div className={"flex items-center justify-center"}>
                    <div className="xl:w-3/4">
                        <ServiceDescription
                            title={"Cloud Hosting"}
                            iconSrc="/images/icons-01-sm.svg"
                            description={"Met onze cloud hosting oplossingen profiteert u van betrouwbare, schaalbare en veilige hosting voor uw website of applicatie. Of u nu een eenvoudige website of een complexe webapplicatie beheert, wij zorgen voor optimale prestaties, snelle laadtijden en maximale uptime."}
                            features={hostingFeatures}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
