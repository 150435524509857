export function Card(props) {
    const {title, description, icon} = props;
    return (
        <div
            className="flex justify-center items-center transform transition-transform duration-300 hover:-translate-y-2"
        >
            <div
                className="single-features-card text-center text-gray-500 bg-white shadow-lg rounded-lg max-w-xs h-full"
                data-aos="fade-up"
                data-aos-once="false"
                data-aos-delay="800"
            >
                <div className="p-6 flex flex-col items-center">
                    <i className={`${icon} text-4xl text-purple`}></i>
                    <h3 className="text-xl font-semibold mt-4">
                        <a href="/service-details/" className="hover:underline">
                            {title}
                        </a>
                    </h3>
                    <p className="text-gray-600 mt-4">
                        {description}
                    </p>
                </div>
            </div>
        </div>
    );
}
